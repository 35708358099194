@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
  /* outline:none; */
  /* overflow: none; */
}

/* Remove outline on the forms and links */
:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

input {
  outline: none;
}

.b2px46 {
  border: 2px solid #b3b3b3 !important;
}

.b2px46-error {
  border: 2px solid #eb1111 !important;
}

button {
  outline: none;
}

/* change colours to suit your needs */

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

:root {
  --grayd3-color: #D3D3D3;
  --grayac-color: #ACACAC;
  --graybe-color: #BEBEBE;
  --pink-color: #EC25A3;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;              мешает mat-tab
  transition: all 0.4s; */
}

body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  overflow-x: hidden;
}

.images {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* -o-object-fit: contain;
     object-fit: contain; */
}

::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 0;
  height: 0;
}

::-webkit-scrollbar {
  height: 16px;
  background-color: #f9f9fd;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #B5BDC0;
  height: 5px;
  width: 8px;
  border: 4px solid white;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #525d60;
}

::-webkit-resizer {
  background-repeat: no-repeat;
  width: 4px;
  height: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

header {
  position: fixed;
  width: 120px;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background: #FFFFFF;
  -webkit-box-shadow: 8px 0 32px rgba(0, 0, 0, 0.08);
  box-shadow: 8px 0 32px rgba(0, 0, 0, 0.08);
}

.header__wrapper {
  padding: 42px 0 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header__logo-block {
  width: 80px;
  height: 50px;
  margin: 0 auto;
}

.header__logo-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.header__navigation {
  margin-top: 32px;
}

.header__navigation ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.header__navigation li {
  width: 120px;
  height: 78px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.6;
}

.header__navigation li:hover {
  opacity: 1;
  background-color: #F8F8FA;
}

.header__navigation a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.header__navigation a img {
  width: 24px;
  height: 24px;
}

.header__navigation a p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  display: block;
  margin-top: 8px;
}

.header__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header__settings {
  width: 19px;
  height: 20px;
  cursor: pointer;
}

.header__settings img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.header__bottom-text {
  display: none;
}

.header__whose-account {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-top: 32px;
  cursor: pointer;
}

.header__whose-account img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* -o-object-fit: contain;
     object-fit: contain; */
}

.users-list {
  background-color: #F8F8FA;
}

.users-list__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 40px;
}

.users-list__top-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.users-list .select {
  position: relative;
  display: block;
  width: 100%;
  max-width: 185px;
  margin-bottom: 20px;
  background: #EA5C0A;
  border-radius: 6px;
  height: 48px;
}

.users-list .select__head {
  width: 100%;
  max-width: 100%;
  padding: 13px 16px 13px 24px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.users-list .select__header-img-block {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.users-list .select__head::after {
  width: 12px;
  height: 6px;
  background: url("/img/arrow-white-add.svg") no-repeat center/cover;
  position: absolute;
  right: 14px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.users-list .select__head::before {
  content: '';
  display: block;
  height: 48px;
  width: 1px;
  background-color: #F8F8FA;
  position: absolute;
  right: 40px;
  top: 0;
  opacity: 0.2;
}

.users-list .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.users-list .select.js-opacity {
  background: rgba(234, 92, 10, 0.8);
}

.users-list .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 6px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  scrollbar-color: dark;
  scrollbar-width: thin;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.users-list .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.users-list .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.users-list .select__list .select__item {
  position: relative;
  padding: 14px 24px;
  cursor: pointer;
  list-style-type: none;
}

.users-list .select__list .select__item:hover {
  background-color: rgba(234, 92, 10, 0.8);
}

.users-list .select__item {
  border-bottom: 1px solid rgba(248, 248, 250, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  background-color: #ea5c0a;
}

.users-list .select__item-img {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 9px;
}

.users-list__title {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  font-family: 'Oswald', sans-serif;
}

.users-list__line {
  width: 1px;
  height: 48px;
  background: #B5BDC0;
  margin: 0 24px;
}

.users-list__search-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.users-list__search-img-block {
  width: 21px;
  height: 19px;
  margin-right: 18px;
}

.users-list__search-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.users-list__search-input {
  width: 100%;
  max-width: 400px;
  height: 18px;
  border: none;
  background-color: transparent;
}

.users-list__search-input::-webkit-input-placeholder {
  color: #97A2A7;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
}

.users-list__search-input::-moz-placeholder {
  color: #97A2A7;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
}

.users-list__search-input:-ms-input-placeholder {
  color: #97A2A7;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
}

.users-list__search-input::-ms-input-placeholder {
  color: #97A2A7;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
}

.users-list__search-input::placeholder {
  color: #97A2A7;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
}

.users-list__add {
  width: 185px;
  height: 48px;
  border-radius: 6px;
}

.users-list__blocks-companies {
  width: 100%;
  max-width: 1272px;
  margin: 0 auto 40px;
}

.users-list__blocks-companies-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 16px;
}

.users-list__blocks-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.users-list__blocks-forimg {
  background: #FFFFFF;
  border: 1px solid rgba(181, 189, 192, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.users-list__blocks-companies-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.users-list__blocks-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 12px 0 13px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.users-list__blocks-images {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.users-list__blocks-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #EA5C0A;
}

.users-list__items-noscroll {
  overflow: auto;
}

.users-list__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 198px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin-bottom: 24px;
}

.users-list__block-item {
  background: #FFFFFF;
  border: 1px solid rgba(181, 189, 192, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 152px !important;
  margin-right: 8px;
  padding-top: 24px;
  height: 220px;
  border-bottom: 3px solid red;
}

.users-list__block-item-wait {
  background: #FFFFFF;
  border: 1px solid rgba(181, 189, 192, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 152px !important;
  margin-right: 8px;
  padding-top: 24px;
  height: 220px;
  border-bottom: 3px solid #ffa500;
}

.users-list__block-item-active {
  background: #FFFFFF;
  border: 1px solid rgba(181, 189, 192, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 152px !important;
  margin-right: 8px;
  padding-top: 24px;
  height: 220px;
  border-bottom: 3px solid green;
}

.users-list__block-item-add {
  background: #FFFFFF;
  border: 1px solid rgba(181, 189, 192, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 152px !important;
  margin-right: 8px;
  padding-top: 24px;
  height: 220px;
}

.users-list__block-who {
  position: relative;
  margin-bottom: 12px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.users-list__block-img-lock {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
}

.users-list__block-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.users-list__block-surname {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.users-list__block-norimg {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#DBE0E1), to(#B5BDC0));
  background: -o-linear-gradient(top, #DBE0E1 0%, #B5BDC0 100%);
  background: linear-gradient(180deg, #DBE0E1 0%, #B5BDC0 100%);
  border-radius: 50%;
}

.users-list__norimg-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  font-family: 'Rubik', sans-serif;
}

.users-list__block-forplus {
  cursor: pointer;
}

.users-list__block-item-fotplus {
  padding-top: 37px;
}

.users-list__block-spec {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  color: #EA5C0A;
}

.users-list__block-request {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  text-align: center;
  color: #708489;
  margin-top: 8px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.users-list__block-item-fotplus:hover .users-list__block-forplus,
.users-list__block-item-fotplus:hover .users-list__block-spec {
  opacity: 0.5;
}

.users-list__blocks-add:hover .users-list__blocks-images,
.users-list__blocks-add:hover .users-list__blocks-text {
  opacity: 0.5;
}

.container {
  width: calc(100% - 176px - 56px);
  position: relative;
  left: 176px;
}

.card-user-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 888px;
  z-index: 10;
  /* display: none; */
}

.card-user-popup__wrapper {
  padding: 56px 0;
}

.card-user-popup__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 40px;
}

.card-user-popup__person-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-user-popup__person-img-block {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
}

.card-user-popup__full-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  text-transform: capitalize;
}

.card-user-popup__other {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-user-popup__round {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background: #F8F8FA;
}

.card-user-popup__round:hover {
  background: #e1e1ea;
}

.card-user-popup__edit {
  margin-right: 16px;
}

.card-user-popup__edit-img {
  width: 14px;
  height: 14px;
}

.card-user-popup__close-img {
  width: 8px;
  height: 8px;
}

.card-user-popup__hr {
  width: 100%;
  height: 1px;
  background: #E9E9E9;
  margin: 40px 0;
}

.card-user-popup__title {
  padding: 0 40px;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 24px;
}

.card-user-popup__contacts {
  padding: 0 60px;
}

.card-user-popup__contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.card-user-popup__contact:last-child {
  margin-bottom: 0;
}

.card-user-popup__icon-link {
  width: 24px !important;
  height: 24px;
  margin-right: 36px;
}

.card-user-popup__contact-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.card-user-popup__contact-link-company {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #000000;
}

.card-user-popup__contact-link {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  text-decoration: none;
}

.card-user-popup.js-active {
  display: block;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 5;
}

.bg.js-active {
  display: block;
}

.card-edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  width: 888px;
  /* display: none; */
  z-index: 11;
  overflow-y: scroll;
}

.card-edit-popup__wrapper {
  padding: 32px 0 40px;
  width: 100%;
}

.card-edit-popup__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 40px;
}

.card-edit-popup__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.card-edit-popup__hr {
  margin: 32px 0 16px;
  height: 1px;
  width: 100%;
  background: #E9E9E9;
}

.card-edit-popup__full-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 40px;
  margin-bottom: 36px;
}

.card-edit-popup__edit-photo {
  position: relative;
  width: 63px;
  height: 56px;
  border-radius: 50%;
  margin-right: 28px;
  cursor: pointer;
}

.card-edit-popup__chose-photo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card-edit-popup__infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.card-edit-popup label {
  width: calc(100% / 3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 24px;
}

.card-edit-popup label:last-child {
  margin-right: 0;
}

.card-edit-popup__label {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: block;
  color: #708489;
  margin-bottom: 8px;
}

.card-edit-popup__input {
  border: none;
  outline: none;
  padding: 15px 16px;
  background: #F8F8FA;
  border-radius: 4px;
  font-weight: normal;
  font-size: 13px;
  color: #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.card-edit-popup__others-info {
  padding-left: 60px;
}

.card-edit-popup__other-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin-bottom: 24px;
}

.card-edit-popup__other-block .card-user-popup__icon-link {
  margin-top: 12px;
}

.card-edit-popup__selectors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: calc(100% - 24px - 12px);
}

.card-edit-popup__selector {
  width: calc(100% / 2 - 24px);
  margin-right: 24px;
}

.card-edit-popup__selector:last-child {
  margin-right: 0;
}

.card-edit-popup .select {
  position: relative;
  display: block;
  background: #F8F8FA;
  border-radius: 4px;
}

.card-edit-popup .select__head {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  padding: 15px 28px 15px 16px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.card-edit-popup .select__head::after {
  width: 10px;
  height: 6px;
  background: url("/img/arrow-down-select.svg") no-repeat center/cover;
  position: absolute;
  right: 14px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.card-edit-popup .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.card-edit-popup .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  scrollbar-color: dark;
  scrollbar-width: thin;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.card-edit-popup .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.card-edit-popup .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.card-edit-popup .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.card-edit-popup .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.card-edit-popup__other-block-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 292px;
  margin-bottom: 24px;
}

.card-edit-popup__other-block-contact:last-child {
  margin-bottom: 40px;
}

.card-edit-popup__other-block-contact label {
  width: 100%;
}

.card-edit-popup__bottoms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 40px;
}

.card-edit-popup__delete-btn {
  background-color: rgba(244, 45, 60, 0.15);
  border-radius: 6px;
  padding: 16px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.card-edit-popup__delete-btn:hover {
  background-color: rgba(244, 45, 60, 0.25);
}

.card-edit-popup__basket-img {
  display: block;
  width: 18px;
  height: 20px;
  margin-right: 12px;
}

.card-edit-popup__basket-span {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #F42D3C;
}

.card-edit-popup__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-edit-popup__removal {
  border: 2px solid rgba(255, 112, 39, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px 45px;
  background-color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FF5601;
  cursor: pointer;
  margin-right: 24px;
  width: 156px;
}

.card-edit-popup__removal:hover {
  background-color: #FF5601;
  border: 2px solid #ff7027;
  color: #FFFFFF;
}

.card-edit-popup__conservation {
  border: 2px solid #ff7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px 30px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFffff;
  cursor: pointer;
  background-color: #FF5601;
  width: 156px;
}

.card-edit-popup__conservation:hover {
  background-color: #FFFFFF;
  border: 2px solid rgba(255, 112, 39, 0.2);
  color: #FF5601;
}

.card-edit-popup.js-active {
  display: block;
}

.card-add-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  width: 888px;
  /* display: none; */
  z-index: 10;
  overflow-y: scroll;
}

.card-add-popup__wrapper {
  padding: 32px 0 40px;
}

.card-add-popup .select {
  position: relative;
  display: block;
  background: #F8F8FA;
  border-radius: 4px;
}

.card-add-popup .select__head {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  padding: 15px 28px 15px 16px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
}

.card-add-popup .select__head::after {
  width: 10px;
  height: 6px;
  background: url("/img/arrow-down-select.svg") no-repeat center/cover;
  position: absolute;
  right: 14px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.card-add-popup .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.card-add-popup .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  scrollbar-color: dark;
  scrollbar-width: thin;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.card-add-popup .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.card-add-popup .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.card-add-popup .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.card-add-popup .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.card-add-popup .card-edit-popup__bottoms {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.card-add-popup__occupation-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.card-add-popup .card-edit-popup__bottoms {
  padding: 0 16px;
}

.card-add-popup.js-active {
  display: block;
}

.add-organization-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  width: 888px;
  /* display: none; */
  z-index: 10;
  overflow-y: scroll;
}

.add-organization-popup__wrapper {
  padding: 32px 0 40px;
}

.add-organization-popup__block-inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.add-organization-popup__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  padding: 0 40px;
}

.add-organization-popup__hr {
  background: #E9E9E9;
  width: 100%;
  height: 1px;
  margin: 32px 0 16px;
}

.add-organization-popup .select {
  position: relative;
  display: block;
  background: #F8F8FA;
  border-radius: 4px;
  width: 100%;
}

.add-organization-popup .select__head {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  padding: 15px 28px 15px 16px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.add-organization-popup .select__head::after {
  width: 10px;
  height: 6px;
  background: url("/img/arrow-down-select.svg") no-repeat center/cover;
  position: absolute;
  right: 14px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.add-organization-popup .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.add-organization-popup .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  scrollbar-color: dark;
  scrollbar-width: thin;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.add-organization-popup .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.add-organization-popup .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.add-organization-popup .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.add-organization-popup .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.add-organization-popup__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-items: center;
  padding: 0 42px;
  margin-bottom: 40px;
}

.add-organization-popup__block label {
  width: 100%;
}

.add-organization-popup__selector {
  width: 100%;
  max-width: 224px;
  margin-right: 24px;
}

.add-organization-popup__address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0 40px 0 50px;
  margin-bottom: 42px;
}

.add-organization-popup__address label {
  width: 100%;
}

.add-organization-popup .card-edit-popup__bottoms {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.add-organization-popup .card-edit-popup__conservation {
  width: 174px;
}

.add-organization-popup.js-active {
  display: block;
}

.organization-step2-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 889px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  /* display: none; */
  z-index: 11;
}

.organization-step2-popup__block-inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.organization-step2-popup__wrapper {
  padding: 32px 0 40px;
}

.organization-step2-popup .select {
  position: relative;
  display: block;
  background: #F8F8FA;
  border-radius: 4px;
}

.organization-step2-popup .select__head {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  padding: 15px 28px 15px 16px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
}

.organization-step2-popup .select__head::after {
  width: 10px;
  height: 6px;
  background: url("/img/arrow-down-select.svg") no-repeat center/cover;
  position: absolute;
  right: 14px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.organization-step2-popup .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.organization-step2-popup .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  scrollbar-color: dark;
  scrollbar-width: thin;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.organization-step2-popup .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.organization-step2-popup .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.organization-step2-popup .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.organization-step2-popup .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.organization-step2-popup.js-active {
  display: block;
}

.security-page {
  background: #F8F8FA;
  height: 100vh;
}

.security-page__search-block {
  display: none;
}

.security-page__top-spec-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security-page .tab {
  overflow: hidden;
  background-color: transparent;
  padding-left: 56px;
}

.security-page .tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 14px 14px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 4px solid transparent;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #708489;
  position: relative;
}

.security-page .tab button:hover {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  color: #000000;
}

.security-page .tab button.js-active {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.security-page .tab button.js-active:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px 4px 0 0;
  background-color: black;
  bottom: 0;
}

.security-page .tabcontent {
  display: none;
  border-top: none;
  height: 100%;
}

.security-page .tabcontent:nth-child(1) {
  display: block;
}

.security-page__container {
  width: calc(100% - 120px);
  position: relative;
  left: 120px;
}

.security-page .users-list__top {
  padding: 40px 56px 0;
  margin-bottom: 40px;
}

.security-page .users-list__search-block {
  width: 100%;
  max-width: 376px;
  min-width: 292px;
  margin-right: 98px;
}

.security-page .users-list__title {
  white-space: nowrap;
}

.security-page__calendar {
  width: 100%;
  max-width: 234px;
  min-height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.security-page__calendar .select {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 12px 30px 12px 8px;
}

.security-page__calendar .select__head {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  position: relative;
}

.security-page__calendar .select__head:after {
  position: absolute;
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("/img/arrow-calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  right: -32px;
  top: -3px;
}

.security-page__calendar .select__head.open::after {
  -webkit-transform: translateY(0%) rotate(180deg);
  -ms-transform: translateY(0%) rotate(180deg);
  transform: translateY(0%) rotate(180deg);
}

.security-page__calendar .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  padding: 0;
  font-size: 14px;
  color: #424348;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.security-page__calendar .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.security-page__calendar .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.security-page__calendar .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.security-page__calendar .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.security-page__calendar .select__list {
  background: #FFFFFF;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.12);
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 0;
  width: 100%;
  max-height: 316px;
}

.security-page__calendar .select__item {
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border: none !important;
  background-color: white;
}

.security-page__calendar .select__item:hover {
  background: #F1F1F2;
}

.security-page .security-page__calendar .background {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.security-page__calendar-image-block {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.security-page__create {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #EA5C0A;
  border-radius: 6px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 13px 24px;
  white-space: nowrap;
  cursor: pointer;
}

.security-page__create-img-block {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.security-page__create-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}

.security-page__main {
  height: auto;
}

.security-page__all-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 32px 24px 48px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.security-page__title-category {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__all-info {
  padding: 0 32px;
}

.security-page__item {
  background: #FFFFFF;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 2px;
  cursor: pointer;
}

.security-page__item-director {
  background: #464646;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 2px;
  cursor: pointer;
}

.security-page__info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 70px;
  max-height: 70px;
  position: relative;
}

.security-page__info-item:after {
  content: '';
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  /* background-image: url("/img/arrow-calendar.svg"); */
  background-position: center;
  background-repeat: no-repeat;
  right: 16px;
  top: 23px;
}

.security-page__id {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  padding: 0 0 0 16px;
}

.security-page__to-whom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 2px;
}

.security-page__photo {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.security-page__info-person-from {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.security-page__info-name-person {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 4px;
}

.security-page__info-company {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #708489;
}

.security-page__info-document {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security-page__info-document-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #708489;
  margin-right: 8px;
}

.security-page__info-document-number {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #708489;
}

.security-page__way {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.security-page__way-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.security-page__way-icon svg {
  fill: black;
  width: 24px;
  height: 24px;
}

.security-page__way-icon:last-child {
  margin-right: 0;
}

.security-page__line {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.2;
  margin: 0 8px;
}

.security-page__way-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
}

.security-page__arrive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.security-page__arrive-photo {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  position: relative;
}

.security-page__arrive-online-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
}

.security-page__arrive-date {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__arrive-details-block {
  position: absolute;
  background: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  width: 344px;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 8%);
  -ms-transform: translate(-50%, 8%);
  transform: translate(-50%, 8%);
  /* display: none; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
}

.security-page__arrive-details-wrapper {
  padding: 20px 40px 40px;
  width: 100%;
}

.security-page__arrive-id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
  margin-bottom: 24px;
}

.security-page__arrive-time {
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.security-page__arrive-input {
  width: 100%;
  padding: 15px 16px;
  margin-bottom: 24px;
  background: #F8F8FA;
  border-radius: 4px;
  border: none;
}

.security-page__arrive-input::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.security-page__arrive-input::-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.security-page__arrive-input:-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.status {
  display: inherit;
  margin-right: 2%;
}

.security-page__arrive-input::-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.security-page__arrive-input::placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.security-page__arrive-btn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  color: #FFFFFF;
}

.security-page__departure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.security-page__departure-details {
  background: #FFFFFF;
  -webkit-box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  width: 318px;
  position: absolute;
  z-index: 6;
  display: none;
  right: 0;
  top: 42px;
}

.security-page__departure-details-wrapper {
  width: 100%;
  padding: 16px 40px 40px;
}

.security-page__departure-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}

.security-page__departure-input {
  display: none;
}

.security-page__departure-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__departure-fake {
  display: block;
  width: 18px;
  height: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #B5BDC0;
  border-radius: 3px;
  background-color: #FFFFFF;
  position: relative;
  margin-right: 19px;
}

.security-page__departure-fake:before {
  content: '';
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  top: -2px;
  left: -2px;
  background-color: #EA5C0A;
  background-image: url("/img/check-whitw.svg");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 3px;
  opacity: 0;
}

.security-page__departure-btn {
  width: 100%;
  max-width: 100%;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #B5BDC0;
  border-radius: 6px;
  border: 2px solid #B5BDC0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.security-page__departure-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
}

.security-page__status {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #3e94ff;
}

.security-page__status-expired {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #ff0000;
}

.security-page__arrive-arrive {
  position: relative;
  background-color: #ffe6d9;
  border-radius: 50%;
}

.security-page__arrive-arrive:after {
  display: block;
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  background-image: url("/img/guest-leave.svg");
  background-size: cover;
  width: 14px;
  height: 8px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.security-page__status-come {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #00C66C;
}

.security-page__arrive-nophoto {
  position: relative;
  background-color: rgba(255, 86, 1, 0.15);
  border-radius: 50%;
}

.security-page__arrive-nophoto:after {
  display: block;
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  background-image: url("/img/come-guest.svg");
  background-size: cover;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.security-page__arrive-round {
  background: #F8F8FA;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
}

.security-page__arrive-nodate {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__guest {
  padding: 0 2px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.security-page__guest-come {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  white-space: nowrap;
  color: #FF5601;
}

.security-page__arrive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security-page__arrive-nodate {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #FF5601;
}

.security-page__departure-none {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
}

.security-page__info-details {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.security-page__info-details-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 28px 24px;
}

.security-page__info-details-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.security-page__info-details-border {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 0 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  background: #FFFFFF;
  border: 1px solid #B5BDC0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
}

.security-page__info-details-images {
  padding: 13px 8px;
  border-left: 1px solid #B5BDC0;
}

.security-page__info-details-letters-two {
  margin: 0 5px 0 6px;
}

.security-page__info-details-letters {
  margin-right: 2px;
}

.security-page__info-details-numbers {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  margin-right: 16px;
}

.security-page__info-details-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__info-details-clients {
  margin-top: 41px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__details-org {
  margin-top: 9px;
}

.security-page__details-org-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */
  color: #000000;
  margin-bottom: 16px;
}

.security-page__details-org-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
  margin-bottom: 16px;
  position: relative;
}

.security-page__details-org-text:before {
  content: '';
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("/img/arrow-tab-toright.svg");
  left: -40px;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
}

.security-page__details-org-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #125098;
}

.security-page__details-way-in-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 24px;
}

.security-page__details-way-in-images {
  background: #F8F8FA;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
}

.security-page__details-row-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #708489;
  margin-bottom: 8px;
}

.security-page__details-row-info {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.security-page__details-row-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security-page__info-details-other {
  width: 146px;
}

.security-page__info-details-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background-image: url("/img/frame.svg");
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  text-align: center;
  color: #00C66C;
  margin-bottom: 16px;
}

.security-page__info-details-where {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  text-align: center;
  color: #000000;
}

.security-page__calendar.background {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.w_8 {
  width: 8%;
}

.w_9 {
  width: 9%;
}

.w_14 {
  width: 14%;
}

.w_18 {
  width: 18%;
}

.w_13 {
  width: 13%;
}

.w_20 {
  width: 20%;
}

.w_12 {
  width: 12%;
}

.w_10 {
  width: 10%;
}

.w_7 {
  width: 7%;
}

.w_22 {
  width: 22%;
}

.w_16 {
  width: 16%;
}

.w_17 {
  width: 17%;
}

.w_15 {
  width: 15%;
}

.w_19 {
  width: 19%;
}

.w_29 {
  width: 29%;
}

.w_23 {
  width: 23%;
}

.security-page__arrive.js-active .security-page__arrive-details-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.security-page__departure-input:checked + .security-page__departure-fake:before {
  opacity: 1;
}

.security-page__departure-btn.js-active {
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
}

.security-page__departure-details.js-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security-page__item.js-active .js-security-page__info-details {
  height: auto;
}

.security-page__item.js-active .security-page__info-item:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.security-page__info {
  position: relative;
}

.security-page__guest-after {
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 5;
  background: #F8F8FA;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  border-radius: 50%;
  color: #000000;
  padding: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.security-page__guest-second {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: white;
  border: 4px solid rgba(0, 0, 0, 0.3);
}

.security-page__guest-hover:hover .security-page__guest-second {
  opacity: 1;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  z-index: 6;
  width: 100%;
}

.security-page__guest-second.js-active {
  opacity: 1 !important;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  z-index: 6;
  width: 100%;
  left: 0;
  right: 0;
  top: 100%;
}

.security-page__info-hover {
  margin-bottom: 2px;
}

.security-page__status-waiting {
  color: #ffa500;
}

.entrance {
  background: #F8F8FA;
  height: 100vh;
  width: 100%;
}

.entrance__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 48px 0;
}

.entrance__logo-block {
  width: 100%;
  max-width: 239px;
}

.entrance__text {
  font-family: 'Oswald', sans-serif;
  margin: 24px auto 97px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */
  color: #000000;
}

.entrance__block-form {
  background: #FFFFFF;
  border: 1px solid rgba(181, 189, 192, 0.4);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 48px;
  width: 100%;
  max-width: 480px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.entrance__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.entrance__input-tel {
  margin-bottom: 32px;
}

.entrance__input {
  background: #FFFFFF;
  border: 3px solid #F8F8FA;
  border-radius: 4px;
  padding: 15px 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  display: inline-block;
  margin-bottom: 24px;
  width: 100%;
  height: 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.entrance__input:last-child {
  margin-bottom: 8px;
}

.entrance__input::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.entrance__input::-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.entrance__input:-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.entrance__input::-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.entrance__input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #97A2A7;
}

.entrance__input:valid {
  background-color: #FFFFFF;
}

.entrance__input:invalid {
  background-color: #F8F8FA;
  border: none;
}

.entrance__detail-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}

.entrance__wrong-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  color: #F42D3C;
}

.entrance__remind {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
}

.entrance__remind a {
  color: #EA5C0A;
}

.entrance__input-remind {
  margin-bottom: 8px;
}

.entrance__button {
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
  padding: 16px 106px;
  white-space: nowrap;
  opacity: 1;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.entrance__button-notActive {
  background: #555555 !important;
}

.entrance__button:hover {
  opacity: 0.7;
}

.entrance__wrong-hidden {
  visibility: hidden;
}

.entrance__reform-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

.entrance__input-sms {
  width: 58px;
  height: 48px;
  padding: 12px 15px;
  text-align: center;
  margin-right: 16px;
  margin-bottom: 32px;
}

.entrance__input-sms:last-child {
  margin-right: 0;
}

.entrance__input:last-child {
  margin-bottom: 8px;
}

.entrance-sms__title {
  width: 345px;
  text-align: center;
  margin-bottom: 49px;
}

.entrance-sms__text {
  margin-bottom: 48px;
  text-align: center;
}

.entrance-sms__inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.entrance-sms__after-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  color: #93A3A7;
  margin-top: 48px;
  width: 170px;
}

.entrance-new-password__title {
  margin-bottom: 49px;
  width: 250px;
  height: 96px;
  left: 843px;
  top: 222px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #000000;
}

.entrance-new-password__text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

.entrance-new-password__input__input:last-child {
  margin-bottom: 32px;
}

.add-pass-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 960px;
  background-color: #FFFFFF;
  height: 100%;
  max-height: 1070px;
  overflow: scroll;
  /* display: none; */
  z-index: 10;
}

.add-pass-popup__wrapper {
  padding: 56px 96px;
}

.add-pass-popup__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}

.add-pass-popup__top-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.add-pass-popup__title {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-right: 19px;
}

.add-pass-popup__when {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #708489;
}

.add-pass-popup__exit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px;
  border-radius: 50%;
  cursor: pointer;
  background: #F8F8FA;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.add-pass-popup__exit svg {
  width: 8px;
  height: 8px;
  stroke: rgba(0, 0, 0, 0.5);
}

.add-pass-popup__exit:hover {
  background-color: #e1e1ea;
}

.add-pass-popup__exit-img {
  width: 8px;
  height: 8px;
}

.add-pass-popup__info-guest-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 16px;
}

.add-pass-popup__info-guest-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
}

.add-pass-popup__info-guest-amount {
  position: absolute;
  left: -62px;
  top: 40px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #708489;
  display: block;
}

.add-pass-popup__label-surname {
  width: 100%;
  max-width: 496px;
  margin-right: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.add-pass-director-popup__label-surname {
  width: 100%;
  max-width: 100%;
  margin-right: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.add-pass-popup__span-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #708489;
  margin-bottom: 8px;
  display: block;
}

.add-pass-popup__input-full-name {
  padding: 15px 16px;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
  border: none;
  outline: none;
  background: #F8F8FA;
  border-radius: 4px;
}

.add-pass-popup__documents {
  width: 100%;
  max-width: 85px;
  margin-right: 8px;
}

.add-pass-popup__documents .select {
  position: relative;
  display: block;
  width: 100%;
}

.add-pass-popup__documents .select__head {
  width: 100%;
  padding: 15px 20px 15px 16px;
  background: #F8F8FA;
  border-radius: 4px;
  color: rgba(66, 67, 72, 0.8);
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  position: relative;
}

.add-pass-popup__documents .select__head::after {
  width: 12px;
  height: 7px;
  background-image: url("/img/select-arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 14px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.add-pass-popup__documents .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.add-pass-popup__documents .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 5px 0 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.add-pass-popup__documents .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.add-pass-popup__documents .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.add-pass-popup__documents .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.add-pass-popup__documents .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.add-pass-popup__label-number-doc {
  border-radius: 4px;
  width: 100%;
  max-width: 147px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.add-pass-popup__number-doc {
  width: 100%;
  background-color: #F8F8FA;
  border-radius: 4px;
  padding: 15px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  border: none;
}

.add-pass-popup__info-guest-delete {
  position: absolute;
  right: -36px;
  width: 17px;
  height: 18px;
  top: 40px;
  cursor: pointer;
  display: block;
}

.add-pass-popup__info-guest-delete-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.add-pass-popup__add-new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.add-pass-popup__images {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.add-pass-popup__add-img {
  width: 11px;
  height: 11px;
}

.add-pass-popup__add-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #EA5C0A;
}

.add-pass-popup__entry {
  margin-top: 32px;
}

.add-pass-popup__entry-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 16px;
}

.add-pass-popup .tab {
  overflow: hidden;
  background: #F8F8FA;
  border-radius: 4px;
  padding: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 48px;
}

.add-pass-popup .tab button {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: #F8F8FA;
  border-radius: 4px;
  padding: 9px;
  height: 100%;
  width: 33%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
  overflow-y: hidden;
}

.add-pass-popup .tab button svg {
  width: 24px;
  height: 24px;
  fill: black;
}

.add-pass-popup .tab button:hover {
  background: #EA5C0A;
  border-radius: 4px;
  color: white;
}

.add-pass-popup .tab button:hover svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.add-pass-popup .tab button:hover .add-pass-popup__tab-img {
  display: none;
}

.add-pass-popup .tab button:hover .add-pass-popup__tab-img-white {
  display: block;
}

.add-pass-popup .tab button.js-active {
  color: #fff;
  background: #EA5C0A;
  border-radius: 4px;
}

.add-pass-popup .tab button.js-active svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.add-pass-popup__tablink-text {
  display: block;
  margin-left: 8px;
}

.add-pass-popup .tab button.js-active .add-pass-popup__tab-img {
  display: none;
}

.add-pass-popup .tab button.js-active .add-pass-popup__tab-img-white {
  display: block;
}

.add-pass-popup .tabcontent {
  display: none;
  border-top: none;
  margin-top: 24px;
}

.add-pass-popup__tab-img {
  margin-right: 5px;
}

.add-pass-popup__tab-img-white {
  display: none;
  margin-right: 5px;
}

.add-pass-popup__tab-select {
  width: 100%;
  max-width: 232px;
}

.add-pass-popup__tabcontent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.add-pass-popup__tabcontent .select {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #F8F8FA;
  border-radius: 4px;
}

.add-pass-popup__tabcontent .select__head {
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding: 15px 16px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.add-pass-popup__tabcontent .select__head::after {
  width: 10px;
  height: 6px;
  background: #FFF url("/img/select-arrow.svg") no-repeat center/cover;
  position: absolute;
  right: 20px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
  display: block;
  -webkit-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in;
}

.add-pass-popup__tabcontent .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.add-pass-popup__tabcontent .select__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 10px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 5px 0 0;
  padding: 0;
  font-size: 14px;
  color: #424348;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.add-pass-popup__tabcontent .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.add-pass-popup__tabcontent .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.add-pass-popup__tabcontent .select__list .select__item {
  position: relative;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 15px;
  cursor: pointer;
  list-style-type: none;
}

.add-pass-popup__tabcontent .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.add-pass-popup__label-tab {
  width: 100%;
  max-width: 232px;
  margin-right: 32px;
}

.add-pass-popup__tab-input {
  width: 100%;
  background: #F8F8FA;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  border: none;
  color: #000000;
  padding: 15px 16px;
}

.add-pass-popup__checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .add-pass-popup__input-checked {
  display: none;
} */

.add-pass-popup__span-fake {
  display: block;
  position: relative;
  border: 2px solid #B5BDC0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  margin-right: 19px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.add-pass-popup__span-fake:before {
  position: absolute;
  display: block;
  content: '';
  background-image: url("/img/ckecked-hover.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #EA5C0A;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  top: -2px;
  left: -2px;
  opacity: 0;
}

.add-pass-popup__purpose {
  margin-top: 32px;
}

.add-pass-popup__purpose-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 16px;
}

.add-pass-popup__purpose-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #F8F8FA;
  border-radius: 4px;
  height: 160px;
}

.add-pass-popup__purpose-textarea {
  width: 100%;
  padding: 27px 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  background: #F8F8FA;
  color: #000000;
  border: none;
  resize: none;
}

.add-pass-popup__purpose-textarea::-webkit-input-placeholder {
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
}

.add-pass-popup__purpose-textarea::-moz-placeholder {
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
}

.add-pass-popup__purpose-textarea:-ms-input-placeholder {
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
}

.add-pass-popup__purpose-textarea::-ms-input-placeholder {
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
}

.add-pass-popup__purpose-textarea::placeholder {
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #708489;
}

.add-pass-popup__purpose-add {
  margin: 27px 24px 0 0;
}

.add-pass-popup__purpose-add .select {
  position: relative;
  display: block;
  width: auto;
  margin-bottom: 20px;
}

.add-pass-popup__purpose-add .select__headd {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #EA5C0A;
}

.add-pass-popup__purpose-add .select__head.open::after {
  -webkit-transform: translateY(50%) rotate(180deg);
  -ms-transform: translateY(50%) rotate(180deg);
  transform: translateY(50%) rotate(180deg);
}

.add-pass-popup__purpose-add .select__list {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  left: auto;
  width: 422px;
  background: #fff;
  border-radius: 10px;
  margin: 5px 0 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  padding: 0;
  font-size: 14px;
  color: #424348;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.add-pass-popup__purpose-add .select__list::-webkit-scrollbar {
  width: 7px;
  background-color: #F8F9FA;
  padding: 5px;
}

.add-pass-popup__purpose-add .select__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.add-pass-popup__purpose-add .select__list .select__item {
  position: relative;
  padding: 24px 68px;
  cursor: pointer;
  list-style-type: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #1B1B1B;
}

.add-pass-popup__purpose-add .select__list .select__item:before {
  content: '';
  display: block;
  position: absolute;
  background-color: #F8F8FA;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  top: 20px;
  background-image: url("/img/arrow-list-purpose.svg");
  background-repeat: no-repeat;
  background-position: center;
  left: 24px;
}

.add-pass-popup__purpose-add .select__list .select__item:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.add-pass-popup__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 32px;
}

.add-pass-popup__bottom-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-pass-popup__btn-cancel {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  border: 2px solid rgba(255, 112, 39, 0.2);
  color: #FF5601;
  white-space: normal;
  padding: 16px 45px;
  background-color: white;
  margin-right: 24px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
}

.add-pass-popup__btn-cancel:hover {
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: #FFFFFF;
}

.add-pass-popup__btn-send {
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  padding: 16px 32px;
}

.add-pass-popup__btn-send:hover {
  border: 2px solid rgba(255, 112, 39, 0.2);
  color: #FF5601;
  white-space: normal;
  background-color: white;
}

.add-pass-popup__btn-create-pass {
  background: #696969;
  border: 2px solid #5a5a5a;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  padding: 16px 32px;
}

.add-pass-popup__btn-create-pass:hover {
  background: #383838;
  border: 2px solid #1a1919;
  /* color: #000; */
  white-space: normal;
}

.add-pass-popup__btn-create-pass-active {
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  padding: 16px 32px;
}

.add-pass-popup__btn-create-pass-active:hover {
  border: 2px solid rgba(255, 112, 39, 0.2);
  color: #FF5601;
  white-space: normal;
  background-color: white;
}

.add-pass-popup__input-checked:checked + .add-pass-popup__span-fake:before {
  opacity: 1;
}

.add-pass-popup.js-active {
  display: block !important;
}

.bg {
  display: none;
}

.bg.js-active {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  display: block;
}

.my-page__settings-copy {
  position: relative;
}

.my-page__settings-copy-block {
  position: absolute;
  top: 100%;
  width: 183px;
  background-image: url("/img/bd-black-.svg");
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 5;
  opacity: 0;
  height: 0;
  overflow: hidden;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  padding: 12px 12px 8px;
  color: #FFFFFF;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.my-page__settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 56px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.my-page__all-top {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: auto;
}

.my-page__settings-round-favour svg {
  stroke: #B5BDC0;
  width: 17px;
  height: 16px;
  fill: transparent !important;
}

.my-page__settings-round-favour {
  width: 17px;
  height: 16px;
}

.my-page__settings-round-favour:hover svg {
  stroke: #ffbc3a;
  width: 17px;
  height: 16px;
  fill: #ffbc3a !important;
}

.my-page__settings-round-edit {
  width: 17px;
  height: 16px;
}

.my-page__settings-round-edit svg {
  stroke: #B5BDC0;
  width: 17px;
  height: 16px;
  fill: transparent !important;
}

.my-page__settings-round {
  padding: 15px;
  background: #F8F8FA;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 48px;
  height: 48px;
  margin-right: 4px;
}

.my-page__settings-round svg {
  fill: #B5BDC0;
  width: 17px;
  height: 17px;
}

.my-page__settings-round:last-child {
  margin-right: 0;
}

.my-page__settings-favourite {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.w_21 {
  width: 21%;
}

.w_20 {
  width: 20%;
}

.w_17 {
  width: 17%;
}

/* .my-pass-popup {
  display: none;
} */

.my-pass-popup.js-active {
  display: block !important;
}

.bg {
  display: none;
}

.bg.js-active {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  display: block;
}

.add-pass-popup__purpose-add-images {
  background: #EA5C0A;
  width: 22px;
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}

.add-pass-popup__purpose-add-images svg {
  fill: white;
  width: 9px;
  height: 10px;
}

.add-pass-popup__purpose-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
}

.add-pass-popup__purpose-add .select {
  margin-bottom: 0;
}

.my-page__settings-round-favour.js-active svg {
  stroke: #ffbc3a;
  width: 17px;
  height: 16px;
  fill: #ffbc3a !important;
}

.my-page__settings-copy:hover .my-page__settings-copy-block {
  opacity: 1;
  height: 38px;
}

.popup-calendar {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  width: 100%;
  max-width: 896px;
  /* display: none; */
  z-index: 10;
}

.popup-calendar__title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin: 32px 96px 0;
}

.popup-calendar__hr {
  background: #E9E9E9;
  width: 100%;
  height: 1px;
  margin: 32px 0;
}

.popup-calendar .calendar {
  height: 350px;
  font-family: 'Roboto', sans-serif;
}

.popup-calendar__but {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 40px 96px 40px 0;
}

.popup-calendar__cancel {
  padding: 16px 45px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FF5601;
  border: 2px solid rgba(255, 112, 39, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #FFFFFF;
  margin-right: 24px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
}

.popup-calendar__cancel:hover {
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  font-size: 18px;
  line-height: 24px;
}

.popup-calendar__do {
  padding: 16px 29px;
  background: #FF7027;
  border: 2px solid #FF7027;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.popup-calendar__do:hover {
  color: #FF5601;
  border: 2px solid rgba(255, 112, 39, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #FFFFFF;
}

.popup-calendar .ui-datepicker .selected-start:not(.selected-end) a,
.popup-calendar .ui-datepicker .selected-end:not(.selected-start) a {
  background: #FF7027;
}

.popup-calendar .ui-datepicker-inline {
  width: 100% !important;
  padding: 0 96px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.popup-calendar .ui-widget.ui-widget-content {
  border: none;
}

.popup-calendar .ui-datepicker-multi-2 .ui-datepicker-group {
  width: 100%;
  max-width: 308px;
}

.popup-calendar .ui-datepicker-multi .ui-datepicker-group {
  float: none !important;
}

.popup-calendar .ui-datepicker-row-break {
  width: 0;
  display: none;
}

.popup-calendar .ui-datepicker .ui-datepicker-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  text-align: center;
  color: #000000;
}

.popup-calendar .ui-state-default,
.popup-calendar .ui-widget-content .ui-state-default,
.popup-calendar .ui-widget-header .ui-state-default,
.popup-calendar .ui-button,
.popup-calendar html .ui-button.ui-state-disabled:hover,
.popup-calendar html .ui-button.ui-state-disabled:active {
  border: none;
  background: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  text-align: center;
  color: #000000;
  padding: 13px;
}

.popup-calendar .ui-datepicker td {
  border: 0;
  padding: 0;
}

.popup-calendar .ui-datepicker th {
  padding: 14px 10px 22px;
  border: 0;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #708489;
}

.popup-calendar .ui-widget-header {
  border: none;
  background: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  color: #000000;
}

.popup-calendar .ui-icon,
.popup-calendar .ui-widget-content .ui-icon {
  background-image: url("/img/stroke-arrow-calendar.svg") !important;
  background-position: center;
}

.popup-calendar .ui-icon,
.popup-calendar .ui-widget-content .ui-datepicker-prev .ui-icon {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.popup-calendar .ui-icon,
.popup-calendar .ui-widget-content .ui-datepicker-next .ui-icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.popup-calendar input {
  opacity: 0;
}

.popup-calendar .selected-start .ui-state-active {
  background-color: #ff7027 !important;
  color: #FFFFFF;
}

.popup-calendar .selected .ui-state-active {
  background-color: rgba(255, 112, 39, 0.1);
}

.popup-calendar.js-active {
  display: block;
}

.add-pass-popup__info-guest-delete svg {
  width: 17px;
  height: 18px;
  stroke: #B5BDC0;
  fill: transparent;
}

.add-pass-popup__images svg {
  fill: #EA5C0A;
  width: 11px;
  height: 11px;
}

.users-list__search-img-block svg {
  stroke: #B5BDC0;
  width: 20px;
  height: 20px;
  fill: transparent;
}

.security-page__bottom {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.security-page__bottom-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.security-page__btn-arrow {
  padding: 8px 13px 7px;
  background-color: #FFFFFF;
  margin-right: 2px;
}

.security-page__btn-arrow svg {
  width: 15px;
  height: 15px;
  fill: transparent;
  stroke: #708489;
  stroke-width: 1.5;
}

.security-page__btn-arrow-next {
  margin-right: 0;
}

.security-page__btn-arrow-active {
  cursor: pointer;
}

.security-page__btn-arrow-active svg {
  stroke: #FF8040;
}

.security-page__btn-location {
  padding: 6px 19px;
  margin-right: 2px;
  background: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #EA5C0A;
  cursor: pointer;
}

.security-page__btn-location:hover {
  background: #EA5C0A;
  color: white;
}

.security-page__btn-location-active {
  background: #EA5C0A;
  color: white;
}

.pedestrian-group {
  border-left: 6px solid red;
}

.truck-car-group {
  border-left: 6px solid green;
}

.car-group {
  border-left: 6px solid #ffa500;
}

.mixed-group {
  border-left: 6px solid #3e94ff;
}

.pass-not-nowday {
  box-shadow: -6px 0 0 0 #de37e2;
}

.pass-nowday {
  box-shadow: -6px 0 0 0 white;
}

.pass-in-range {
  box-shadow: -6px 0 0 0 darkviolet;
}

.security-page__departure.js-active .security-page__departure-details {
  display: block;
}

@media (max-width: 1790px) {
  .status {
    display: none;
  }
}

@media (max-width: 1550px) {
  .security-page .users-list__search-block {
    margin-right: 24px;
  }
}

@media (max-width: 1500px) {
  .security-page__id {
    padding: 0 0 0 7px;
  }
}

@media (max-width: 1450px) {
  .security-page__all-top {
    padding: 24px 22px 24px 24px;
  }

  .security-page__all-info {
    padding: 0 5px;
  }
}

@media (max-width: 1400px) {
  .security-page .users-list__title {
    white-space: normal;
    max-width: 200px;
  }

  .security-page__info-item:after {
    right: 9px;
  }
}

@media (max-width: 1350px) {
  .security-page .tab {
    padding-left: 20px;
  }

  .security-page .tabcontent-all {
    overflow: hidden;
    width: 100%;
  }

  .security-page .users-list__top {
    padding: 40px 10px 0 20px;
  }

  .security-page .users-list__search-block {
    margin-right: 8px;
    max-width: 300px;
  }

  .security-page .users-list__title {
    max-width: 100px;
    font-size: 28px;
    line-height: 34px;
    min-width: 100px;
  }
}

@media (max-width: 1250px) {
  .users-list__title {
    font-size: 35px;
    line-height: 40px;
  }

  .users-list__line {
    height: 40px;
    margin: 0 18px;
  }
}

@media (max-width: 1230px) {
  .security-page__way {
    padding-right: 7px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}

@media (max-width: 1130px) {
  .security-page .users-list__line {
    margin: 0 16px;
  }
}

@media (max-width: 1120px) {
  .security-page__search-block {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    max-width: 200px !important;
    min-width: 200px !important;
  }

  .security-page__search-block {
    min-width: 130px !important;
  }

  /* .security-page .users-list__search-block {
    display: none;
  } */
  .security-page__calendar .select__head {
    white-space: nowrap;
  }

  .security-page__calendar .select__head {
    white-space: normal;
  }
}

@media (max-width: 1070px) {
  .users-list__title {
    width: 100%;
    max-width: 250px;
  }

  .users-list__line {
    height: 40px;
  }
}

.api-list__add {
  /* width: 185px; */
  height: 48px;
  border-radius: 6px;
}

@media (max-width: 991px) {
  header {
    background: #FFFFFF;
    -webkit-box-shadow: 8px 0 32px rgba(0, 0, 0, 0.08);
    box-shadow: 8px 0 32px rgba(0, 0, 0, 0.08);
    bottom: 0;
    width: 100%;
    height: 78px;
    top: auto;
    z-index: 8;
  }

  .add-pass-popup__input-full-name {
    padding: 15px 16px;
    width: 90%;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    border: none;
    outline: none;
    background: #F8F8FA;
    border-radius: 4px;
  }

  .add-pass-popup__info-guest-delete {
    position: absolute;
    right: 0px;
    width: 17px;
    height: 18px;
    top: 40px;
    cursor: pointer;
    display: block;
  }

  .header__wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .header__top {
    width: 56%;
  }

  .header__logo-block {
    display: none;
  }

  .header__navigation {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
  }

  .header__navigation ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .header__bottom {
    margin-right: 32px;
  }

  .header__settings {
    display: none;
  }

  .header__whose-account {
    margin-top: 0;
  }

  .users-list__top-main {
    width: 100%;
  }

  .users-list .select {
    margin-bottom: 0;
  }

  .users-list .select__list {
    top: -104px;
  }

  .users-list__title {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    min-width: 382px;
  }

  .users-list__search-block {
    width: 100%;
  }

  .users-list__add {
    position: fixed;
    bottom: 61px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .api-list__add {
    position: fixed;
    bottom: 61px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .container {
    width: 100%;
    left: 0;
    padding: 0 15px;
  }

  .card-user-popup {
    width: 650px;
  }

  .card-user-popup__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .card-user-popup__person-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card-user-popup__person-img-block {
    margin-bottom: 8px;
    margin-right: 0;
  }

  .card-user-popup__full-name {
    width: 300px;
    text-align: center;
  }

  .card-user-popup__other {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
  }

  .card-user-popup__hr {
    margin: 24px 0;
  }

  .card-edit-popup {
    height: 90%;
    width: 98%;
  }

  .card-edit-popup__wrapper {
    padding: 24px 0;
  }

  .card-edit-popup__full-info {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 8px;
  }

  .card-edit-popup__edit-photo {
    margin-top: 20px;
  }

  .card-edit-popup__infos {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .card-edit-popup label {
    margin-bottom: 8px;
    width: 100%;
    margin-right: 0;
  }

  .card-edit-popup__other-block {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    padding-right: 40px;
  }

  .card-edit-popup__other-block .card-user-popup__icon-link {
    margin-top: 30px;
  }

  .card-edit-popup__selectors {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card-edit-popup__selector {
    margin-bottom: 8px;
    width: 100%;
    margin-right: 0;
  }

  .card-edit-popup__selector:last-child {
    margin-bottom: 0;
  }

  .card-edit-popup__other-block-contact {
    max-width: 100%;
    padding-right: 40px;
  }

  .card-edit-popup__other-block-contact:last-child {
    margin-bottom: 24px;
  }

  .card-edit-popup__bottoms {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .card-edit-popup__delete-btn {
    width: 100%;
    text-align: center;
  }

  .card-edit-popup__actions {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .card-edit-popup__removal {
    width: 50%;
  }

  .card-edit-popup__conservation {
    width: 50%;
    padding: 16px 11px;
  }

  .card-add-popup {
    height: 95%;
    width: 98%;
    max-width: 888px;
  }

  .card-add-popup__wrapper {
    padding: 24px 0 16px;
  }

  .card-add-popup .card-edit-popup__top {
    padding: 0;
  }

  .card-add-popup .card-edit-popup__hr {
    margin: 24px 0;
  }

  .card-add-popup .card-edit-popup__title {
    padding: 0 16px;
  }

  .card-add-popup .card-edit-popup__infos label {
    width: 100%;
    margin-bottom: 24px;
  }

  .card-add-popup .card-edit-popup__infos label:last-child {
    margin-bottom: 20px;
  }

  .card-add-popup .card-edit-popup__full-info {
    padding: 0 16px;
  }

  .card-add-popup .card-edit-popup__other-block-contact {
    padding: 0 16px 0 0;
  }

  .card-add-popup .card-edit-popup__selector {
    margin-bottom: 24px;
  }

  .card-add-popup .card-edit-popup__others-info {
    padding-left: 30px;
  }

  .card-add-popup .card-edit-popup__other-block {
    padding-right: 16px;
    margin-bottom: 0;
  }

  .card-add-popup__occupation-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .add-organization-popup .card-edit-popup__actions {
    margin-bottom: 0;
  }

  .add-organization-popup {
    width: 90%;
    max-width: 100%;
  }

  .add-organization-popup__wrapper {
    padding: 26px 0 14px;
  }

  .add-organization-popup__block-inputs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .add-organization-popup__title {
    padding: 0 16px;
  }

  .add-organization-popup__hr {
    margin: 24px 0;
  }

  .add-organization-popup__block {
    padding: 0 16px;
    margin-bottom: 24px;
  }

  .add-organization-popup__selector {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .add-organization-popup__selector:last-child {
    margin-bottom: 0;
  }

  .add-organization-popup__address {
    padding: 0 16px 0 35px;
    margin-bottom: 24px;
  }

  .add-organization-popup .card-edit-popup__removal {
    margin-right: 16px;
  }

  .add-organization-popup .card-edit-popup__bottoms {
    padding: 0 16px;
  }

  .add-organization-popup .card-edit-popup__conservation {
    width: 50%;
  }

  .organization-step2-popup {
    overflow-y: scroll;
    height: 95%;
    max-width: 900px;
    width: 100%;
  }

  .organization-step2-popup .card-edit-popup__other-block-contact {
    padding-right: 0;
  }

  .organization-step2-popup .card-edit-popup__bottoms {
    padding: 0 16px;
  }

  .organization-step2-popup .card-edit-popup__removal {
    margin-right: 16px;
  }

  .organization-step2-popup .card-edit-popup__infos {
    width: 100%;
    max-width: 100%;
  }

  .organization-step2-popup .card-edit-popup__infos label {
    width: 100%;
    margin-bottom: 24px;
  }

  .organization-step2-popup .card-edit-popup__others-info {
    padding: 0 16px 0 30px;
  }

  .organization-step2-popup .card-edit-popup__full-info {
    padding: 0 16px;
  }

  .organization-step2-popup .card-edit-popup__other-block {
    padding-right: 0;
  }

  .organization-step2-popup__block-inputs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .organization-step2-popup .card-edit-popup__selector {
    margin-bottom: 24px;
  }

  .organization-step2-popup .card-edit-popup__selector:last-child {
    margin-bottom: 0;
  }

  .organization-step2-popup__wrapper {
    padding: 24px 0 16px;
  }

  .security-page__wrapper {
    padding-bottom: 130px;
  }

  .security-page .users-list__line {
    display: none;
  }

  .security-page__container {
    width: 100%;
    left: 0;
  }

  .security-page .users-list__top-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .security-page__create {
    position: fixed;
    bottom: 61px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .security-page__all-top {
    display: none;
  }
  .security-page__all-info {
    width: 100%;
    margin-top: 24px;
  }

  .security-page__item {
    padding: 16px;
  }

  .security-page__item-director {
    padding: 16px;
    background: #464646;
  }

  .security-page__info-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 100%;
  }

  .security-page__info-item:after {
    right: 0;
    top: -3px;
  }

  .security-page__id {
    width: 100%;
  }

  .security-page__to-whom {
    width: 100%;
    margin: 8px 0;
  }

  .security-page__photo {
    display: none;
  }

  .security-page__way {
    width: 100%;
    margin-bottom: 8px;
  }

  .security-page__arrive {
    width: 30%;
    margin-bottom: 8px;
  }

  .security-page__arrive-details-block {
    z-index: 6;
    left: 70%;
    top: auto !important;
    bottom: 70px !important;
  }

  .security-page__departure {
    width: 70%;
  }

  .security-page__departure-details {
    left: 0;
    top: -150px;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .security-page__status {
    width: 100%;
    display: none;
  }

  .security-page__status-expired {
    width: 100%;
    display: none;
  }

  .security-page__status-come {
    width: 100%;
    display: none;
  }

  .security-page__status-waiting {
    width: 100%;
    display: none;
  }

  .security-page__guest {
    width: 100%;
  }

  .security-page__guest-hover:hover .security-page__guest-second {
    opacity: 0;
    height: 0;
  }

  .add-pass-popup__wrapper {
    padding: 24px 16px 16px;
  }

  .add-pass-popup__top {
    margin-bottom: 24px;
  }

  .add-pass-popup__top-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .add-pass-popup__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    color: #000000;
    margin-bottom: 8px;
  }

  .add-pass-popup__when {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */
    color: #708489;
  }

  .add-pass-popup__info-guest-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */
    color: #000000;
  }

  .add-pass-popup__info-guest-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .add-pass-popup__info-guest-amount {
    display: none;
  }

  .add-pass-popup__label-surname {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .add-pass-popup__documents {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .add-pass-popup__label-number-doc {
    max-width: 100%;
  }

  .add-pass-popup__info-guest-delete {
    display: block;
  }

  .add-pass-popup__entry {
    margin-top: 24px;
  }

  .add-pass-popup__entry-title {
    margin-bottom: 24px;
  }

  .add-pass-popup .tab {
    height: auto;
  }

  .add-pass-popup .tab button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 8px 3px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
    height: auto;
    font-size: 13px;
    line-height: 15px;
  }

  .add-pass-popup__tab-img {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .add-pass-popup__tab-img-white {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .add-pass-popup__tab-select {
    max-width: 100%;
  }

  .add-pass-popup__tabcontent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .add-pass-popup__label-tab {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .add-pass-popup__checkbox {
    width: 100%;
    margin-left: 16px;
  }

  .add-pass-popup__purpose {
    margin-top: 24px;
  }

  .add-pass-popup__purpose-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .add-pass-popup__purpose-textarea {
    height: 100%;
    width: 100%;
    padding: 24px 16px;
  }

  .add-pass-popup__purpose-add {
    margin: 27px 16px 27px;
  }

  .add-pass-popup__bottom {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .add-pass-popup__bottom-buttons {
    width: 100%;
  }

  .add-pass-popup__btn-cancel {
    padding: 15px;
    margin-right: 16px;
    width: 50%;
  }

  .add-pass-popup__btn-send {
    padding: 15px;
    width: 50%;
  }

  .my-page__settings {
    padding-right: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .popup-calendar {
    max-width: 650px;
    overflow-x: hidden;
  }

  .popup-calendar__title {
    margin: 15px 15px 0;
  }

  .popup-calendar__but {
    padding: 20px;
  }

  .popup-calendar .ui-datepicker-inline {
    padding: 0;
  }
}

@media (max-width: 890px) {
  .organization-step2-popup {
    overflow-y: scroll;
    height: 95%;
    max-width: 98%;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .security-page .tab {
    width: 800px;
    overflow: scroll;
  }

  .security-page .tab__scroll {
    overflow: scroll;
  }
}

@media (max-width: 768px) {
  header {
    height: 62px;
  }

  .header__wrapper {
    padding: 0;
  }

  .header__top {
    width: 60%;
  }

  .header__bottom-text {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */
    text-align: center;
    text-transform: uppercase;
    color: #93A3A7;
  }

  .header__whose-account {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }

  .users-list__wrapper {
    padding-bottom: 80px;
  }

  .users-list__add {
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 70px;
  }

  .users-list__blocks-companies {
    margin-bottom: 16px;
  }

  .card-user-popup__wrapper {
    padding: 16px 0 32px;
  }

  .card-user-popup__title {
    padding: 0 16px;
  }

  .card-user-popup__contacts {
    padding: 0 15px 0 15px;
  }

  .security-page__id {
    width: 100%;
  }

  .entrance__wrapper {
    padding: 30px 0;
  }

  .entrance__logo-block {
    max-width: 200px;
  }

  .entrance__text {
    font-size: 36px;
    line-height: 42px;
    margin: 24px 0 75px;
  }

  .entrance__block-form {
    padding: 30px 23px;
    margin: 0 5px;
  }
}

@media (max-width: 750px) {
  .security-page__info-company {
    display: none;
  }
}

.copyButton {
  margin-top: -10px !important;
  margin-bottom: 24px !important;
  background: #ea5c0a;
  color: white;
  width: 10%;
  margin-left: 1% !important;
  display: inline-block !important;
  vertical-align: -webkit-baseline-middle !important;
  height: 44px;
}

.copyLongButton {
  margin-top: -10px !important;
  margin-bottom: 24px !important;
  background: #ea5c0a;
  color: white;
  width: 21%;
  margin-left: 1% !important;
  display: inline-block !important;
  vertical-align: -webkit-baseline-middle !important;
  height: 44px;
}

.apiInput {
  width: 78% !important;
  display: inline-block;
  vertical-align: bottom;
}

.deleteButton {
  margin-top: -10px !important;
  margin-bottom: 24px !important;
  background: #6b6b6b;
  color: white;
  width: 10%;
  margin-left: 1% !important;
  display: inline-block !important;
  vertical-align: -webkit-baseline-middle !important;
  height: 44px;
}

@media (max-width: 720px) {
  .copyButton {
    width: 100%;
    margin-top: -10px;
    margin-bottom: 24px;
    background: #ea5c0a;
    color: white;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    height: 44px;
  }

  .copyLongButton {
    width: 100%;
    margin-top: -10px;
    margin-bottom: 24px;
    background: #ea5c0a;
    color: white;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    height: 44px;
  }

  .deleteButton {
    width: 100%;
    margin-top: -10px;
    margin-bottom: 24px;
    background: #6b6b6b;
    color: white;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    height: 44px;
  }

  .apiInput {
    width: 100% !important;
    display: inline-block;
    vertical-align: bottom;
  }
}

@media (max-width: 700px) {
  .security-page__arrive {
    width: 50%;
    margin-bottom: 8px;
  }

  .security-page__departure {
    width: 50%;
    margin-bottom: 8px;
  }
}

@media (max-width: 670px) {
  .card-user-popup {
    width: 98%;
    max-width: 650px;
  }
}

@media (max-width: 650px) {
  .users-list__top-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .users-list__title {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .users-list__line {
    display: none;
  }

  .users-list__search-block {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .users-list__search-img-block {
    width: 20px;
  }

  .users-list__search-input {
    max-width: 300px;
  }

  .users-list__blocks-companies-top {
    margin-top: 31px;
  }

  .users-list__blocks-companies-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  .users-list__blocks-add {
    padding: 0 16px;
  }

  .users-list__blocks-images {
    margin-right: 0;
  }

  .users-list__blocks-text {
    display: none;
  }

  .container {
    padding: 0 0 0 15px;
  }

  .security-page__top-spec-block {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .security-page .tab {
    padding-left: 8px;
  }

  .security-page .tab button {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    text-align: center;
    padding: 0 19px 12px;
    color: #708489;
  }

  .security-page .tab button:hover {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    text-align: center;
  }

  .security-page .tab button.js-active {
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    text-align: center;
  }

  .security-page .users-list__top {
    padding: 16px 16px 0;
    margin-bottom: 24px;
  }

  .security-page .users-list__top-main {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .security-page .users-list__title {
    min-width: 100px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    text-align: center;
  }

  .popup-calendar {
    overflow-y: scroll;
    height: 95%;
  }

  .popup-calendar__title {
    margin: 26px 16px;
  }

  .popup-calendar__hr {
    margin: 24px 0;
  }

  .popup-calendar .calendar {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }

  .popup-calendar__but {
    padding: 16px;
  }

  .popup-calendar__buttons {
    width: 100%;
  }

  .popup-calendar__cancel {
    padding: 16px 26px;
    width: 45%;
  }

  .popup-calendar__do {
    padding: 16px 13px;
    width: 45%;
  }

  .popup-calendar .ui-datepicker-inline {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .popup-calendar .ui-icon,
  .popup-calendar .ui-widget-content .ui-icon {
    background-image: none !important;
  }
}

@media (max-width: 500px) {
  .header__top {
    width: calc(100% - 30%);
  }

  .add-organization-popup .card-edit-popup__conservation {
    padding: 16px 8px;
  }

  .security-page__arrive-details-block {
    width: 300px;
    left: 133px;
  }

  .security-page__departure-details {
    width: 300px;
  }
}

@media (max-width: 400px) {
  .card-edit-popup__removal {
    padding: 16px 25px;
  }

  .card-edit-popup__conservation {
    padding: 16px 25px;
  }

  .organization-step2-popup .card-edit-popup__conservation {
    padding: 16px 16px;
  }

  .security-page__search-block {
    min-width: 90px !important;
  }

  .security-page .users-list__search-block {
    max-width: 86px !important;
  }

  .security-page .users-list__search-input {
    max-width: 55px;
    min-width: 50px;
  }

  .security-page__calendar .select__list {
    width: 233px;
    left: 0;
  }
}

@media (max-width: 360px) {
  .security-page__calendar .select__list {
    width: 233px;
    left: calc(100% - 233px);
  }

  .entrance__block-form {
    width: 95%;
  }
}

@media (max-width: 355px) {
  .security-page__calendar .select__head:after {
    right: -27px;
  }
}
